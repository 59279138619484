$t("urunAd")<template>
  <div>
    <v-container class="my-0 my-md-16 py-0  px-0 px-md-3 pb-md-16">
      <v-row class="no-gutters">
        <v-col
          xl="8"
          offset-xl="2"
          md="10"
          offset-md="1"
          sm="12"
          offset-sm="0"
          class="py-0"
        >
          <v-data-table
            :disable-sort="$vuetify.breakpoint.smAndDown ? true : false"
            :headers="headers"
            :items="urunler"
            :search="search"
            :class="
              $vuetify.breakpoint.smAndDown ? 'elevation-0' : 'elevation-24'
            "
            style="border-radius:6px"
          >
            <template v-slot:top>
              <v-toolbar
                flat
                color="primary"
                height="120px"
                dark
                :class="
                  $vuetify.breakpoint.smAndDown
                    ? 'rounded-t-0'
                    : 'rounded-t-lg pl-3'
                "
              >
                <v-toolbar-title class="d-flex align-center">
                  <v-icon large>
                    mdil-view-dashboard
                  </v-icon>
                  <span class="pl-6 text-h4">{{ $t("urunler") }}</span>
                </v-toolbar-title>
                <v-spacer />
                <div
                  v-if="$vuetify.breakpoint.smAndDown ? false : true"
                  class="d-flex py-6 align-center"
                >
                  <v-text-field
                    v-model="search"
                    class="pr-6"
                    append-icon="mdi-magnify"
                    :label="$t('ara')"
                    hide-details
                  />
                </div>
                <v-btn
                  color="accent"
                  dark
                  depressed
                  class="mb-2"
                  @click="dialog = true"
                >
                  {{ $t("yeniEkle") }}
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:header.ad="{ item }">
              {{ $t("urunAd") }}
            </template>

            <template v-slot:header.kategori="{ item }">
              {{ $t("kategori") }}
            </template>

            <template v-slot:header.fiyat="{ item }">
              {{ $t("fiyat") }}
            </template>
            <template v-slot:header.durum="{ item }">
              {{ $t("durum") }}
            </template>
            <template v-slot:item.fiyat="{ item }">
              <div>
                {{ item.fiyat | currency }}
              </div>
            </template>
            <template v-slot:item.kategori="{ item }">
              <div>
                {{ icerikGetir("kategoriler", item.kategori, "ad") }}
              </div>
            </template>
            <template v-slot:item.durum="{ item }">
              <v-icon v-if="item.durum && item.stok" color="accent" medium>
                mdi-check-bold
              </v-icon>
              <v-icon v-else-if="!item.durum" color="primary" medium>
                mdi-cancel
              </v-icon>
              <v-icon v-else color="grey" medium>
                mdi-check-outline
              </v-icon>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                v-if="item.file"
                small
                class="mr-2"
                @click="editItemFile(item)"
              >
                mdi-image
              </v-icon>
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <v-dialog
        v-if="dialog"
        v-model="dialog"
        persistent
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        scrollable
        max-width="500px"
        transition="dialog-bottom-transition"
      >
        <v-card tile :disabled="bekle" :loading="bekle">
          <v-card-title class="py-3 py-md-6">
            <div class="text-center text-h4  text-md-h3 font-weight-medium">
              {{ formTitle }}
            </div>
            <v-spacer />
            <v-btn
              x-small
              fab
              elevation="0"
              color="grey lighten-2"
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="px-2 py-0" style="max-height: 100%;">
            <div id="scrollItem" class="scroll pa-3">
              <v-text-field
                v-model="editedItem.ad"
                :error="error && editedItem.ad == ''"
                outlined
                rounded
                hide-details
                class="mb-6 mb-md-9"
                :label="$t('urunAd')"
              />
              <v-textarea
                v-model="editedItem.aciklama"
                hide-details
                outlined
                rounded
                rows="2"
                auto-grow=""
                :label="$t('aciklama')"
                class="mb-6 mb-md-9"
              />
              <v-select
                v-model="editedItem.kategori"
                :error="error && editedItem.kategori == ''"
                outlined
                rounded
                hide-details
                :label="$t('kategori')"
                :items="kategoriler"
                item-text="ad"
                item-value=".key"
                class="mb-6 mb-md-9"
              />
              <v-select
                v-model="editedItem.bilgilendirmeler"
                outlined
                rounded
                hide-details
                :label="$t('bilgilendirme')"
                :items="bilgilendirmeler"
                item-text="ad"
                item-value=".key"
                clearable
                multiple
                class="mb-6 mb-md-9"
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0" class="text-caption">
                    {{
                      $t("adetSecim", {
                        miktar: editedItem.bilgilendirmeler.length
                      })
                    }}
                  </span>
                </template>
              </v-select>
              <v-file-input
                v-if="typeof editedItem.file != 'string'"
                v-model="editedItem.file"
                :label="$t('gorsel')"
                outlined
                rounded
                hide-details
                append-icon="mdi-camera"
                prepend-icon=""
                accept="image/png, image/jpeg, image/jpg"
                class="mb-6 mb-md-9"
              />
              <v-toolbar
                color="grey lighten-2"
                :class="
                  editedItem.kisisellestirmeler.length > 0
                    ? ' rounded-pill'
                    : 'mb-6 mb-md-9 rounded-pill'
                "
                flat
              >
                <v-icon large class="ml-1" color="primary">
                  mdi-tag
                </v-icon>
                <v-spacer />
                <v-btn
                  rounded
                  depressed
                  color="primary text-capitalize mr-n2 mr-md-0"
                  @click="dialogKisisellestirme = true"
                >
                  <v-icon left="">
                    mdi-plus
                  </v-icon>
                  {{ $t("kisisellestirmeEkle") }}
                </v-btn>
              </v-toolbar>
              <div
                v-if="editedItem.kisisellestirmeler.length > 0"
                :class="
                  editedItem.kisisellestirmeler.length > 0
                    ? 'mb-6 mb-md-9 grey lighten-2 mt-n6 mt-md-n7  pt-6 pb-3 rounded-b-xl'
                    : ' grey lighten-2 mt-n6 mt-md-n7  pt-6 pb-3 rounded-b-xl'
                "
              >
                <draggable
                  v-model="editedItem.kisisellestirmeler"
                  group="kisisellestirmeler"
                  handle=".handle"
                  style="width:100%"
                >
                  <div
                    v-for="(kisisellestirme,
                    ki) in editedItem.kisisellestirmeler"
                  >
                    <v-list-item :key="ki">
                      <v-list-item-action class="mr-3 handle">
                        <v-icon>mdi-drag-vertical</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ kisisellestirme.ad }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action class="ml-0">
                        <v-btn
                          small
                          fab
                          class="elevation-0"
                          @click="kisisellestirmeDuzenle(ki)"
                        >
                          <v-icon color="grey lighten-1">
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-action class="ml-2">
                        <v-btn small icon @click="kisisellestirmeSil(ki)">
                          <v-icon small color="grey lighten-1">
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider
                      v-if="editedItem.kisisellestirmeler.length > ki + 1"
                    />
                  </div>
                </draggable>
              </div>

              <div class="d-flex align-center mb-6 mb-md-9">
                <v-text-field
                  v-model.number="editedItem.sira"
                  type="number"
                  min="0"
                  outlined
                  rounded
                  hide-details
                  max="1000"
                  :label="$t('sira')"
                  class="mr-3"
                  style="min-width:100px"
                />
                <v-currency-field
                  v-model="editedItem.fiyat"
                  :label="$t('fiyat')"
                  :error="error && editedItem.fiyat == ''"
                  suffix="€"
                  outlined
                  rounded
                  hide-details
                />
              </div>
              <div class="d-flex align-center mb-6 mb-md-9">
                <v-switch
                  v-model="editedItem.indirimDurum"
                  outlined
                  rounded
                  hide-details
                  color="primary"
                  class="ma-0"
                  inset
                  :label="
                    `${
                      editedItem.indirimDurum
                        ? $t('indirimVar')
                        : $t('indirimYok')
                    }`
                  "
                />
                <v-text-field
                  v-if="editedItem.indirimDurum"
                  v-model.number="editedItem.indirimOran"
                  type="number"
                  min="0"
                  max="99"
                  outlined
                  rounded
                  hide-details
                  suffix="%"
                  :label="$t('indirimOran')"
                  class="ml-3"
                  style="max-width:150px"
                />
              </div>
              <v-switch
                v-model="editedItem.stok"
                outlined
                rounded
                hide-details
                color="primary"
                class="mb-6 mb-md-9"
                inset
                :label="`${editedItem.stok ? $t('stokVar') : $t('stokYok')}`"
              />
              <v-switch
                v-model="editedItem.durum"
                outlined
                rounded
                hide-details
                color="primary"
                class="mb-6 mb-md-9"
                inset
                :label="`${editedItem.durum ? $t('onayli') : $t('onaysiz')}`"
              />
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions class=" px-3 primary rounded-0">
            <div class="px-3" style="width:100%">
              <v-btn
                block
                :loading="bekle"
                :large="$vuetify.breakpoint.smAndDown ? false : true"
                depressed=""
                class="rounded-pill text-capitalize text-body-1"
                color="primary"
                @click="save()"
              >
                {{ formAction }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogFile" width="600">
        <template>
          <v-container>
            <v-row justify="space-around">
              <v-card>
                <v-img height="400px" :src="editedItem.file">
                  <v-app-bar flat color="rgba(0, 0, 0, 0)">
                    <v-toolbar-title class="h3 white--text pl-0">
                      {{ editedItem.ad }}
                    </v-toolbar-title>
                    <v-spacer />
                    <v-btn color="accent" @click="deleteItemFile()">
                      <v-icon left>
                        mdi-close
                      </v-icon>
                      {{ $t("sil") }}
                    </v-btn>
                  </v-app-bar>
                </v-img>
              </v-card>
            </v-row>
          </v-container>
        </template>
      </v-dialog>
      <v-dialog
        v-model="dialogKisisellestirme"
        persistent
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        scrollable
        max-width="500px"
        overlay-color="#ffffff"
        overlay-opacity="1"
      >
        <v-card :class="$vuetify.breakpoint.smAndDown ? 'rounded-0' : ''">
          <v-card-title class="py-3 py-md-6">
            <div class="text-center text-h4  text-md-h3 font-weight-medium">
              {{ $t("kisisellestirmeOlustur") }}
            </div>
            <v-spacer />
            <v-btn
              x-small
              fab
              elevation="0"
              color="grey lighten-2"
              @click="dialogKisisellestirme = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="px-2 py-0" style="max-height: 100%;">
            <div class="scroll pa-3">
              <v-text-field
                v-model="editedKisisellestirmeItem.ad"
                :error="error && editedKisisellestirmeItem.ad == ''"
                outlined
                rounded
                hide-details
                :label="$t('urunAd')"
                class="mb-6 mb-md-9"
              />
              <v-textarea
                v-model="editedKisisellestirmeItem.aciklama"
                hide-details
                outlined
                rounded
                rows="2"
                auto-grow=""
                :label="$t('aciklama')"
                class="mb-6 mb-md-9"
              />
              <v-row no-gutters align="center" class="mb-6 mb-md-9">
                <v-col cols="8" class="pr-3">
                  <v-select
                    v-model="editedKisisellestirmeItem.ogeler"
                    :error="
                      error && editedKisisellestirmeItem.ogeler.length == 0
                    "
                    return-object
                    hide-selected
                    outlined
                    rounded
                    hide-details
                    :label="$t('ogeler')"
                    :items="kisisellestirmeler"
                    item-text="ad"
                    item-value=".key"
                    clearable
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <span v-if="index === 0" class="text-caption ml-n3">
                        {{
                          $t("adetSecim", {
                            miktar: editedKisisellestirmeItem.ogeler.length
                          })
                        }}
                      </span>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    depressed=""
                    rounded=""
                    class="text-capitalize"
                    block
                    @click="dialogKisisellestirmeOge = true"
                  >
                    <v-icon left="">
                      mdi-plus
                    </v-icon>
                    {{ $t("ozelEkle") }}
                  </v-btn>
                </v-col>
              </v-row>
              <div class="grey lighten-2  rounded-lg mb-6 mb-md-9">
                <draggable
                  v-model="editedKisisellestirmeItem.ogeler"
                  group="ogeler"
                  handle=".handle"
                  style="width:100%"
                >
                  <div v-for="(oge, oi) in editedKisisellestirmeItem.ogeler">
                    <v-list-item :key="oi" two-line>
                      <v-list-item-action class="mr-3 handle">
                        <v-icon>mdi-drag-vertical</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ oge.ad }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-chip small color="secondary" class="px-3 mt-1">
                            {{ oge.ilaveFiyat | currency }}
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action
                        v-if="oge.object_key == null"
                        class="ml-0"
                      >
                        <v-btn
                          small
                          fab
                          class="elevation-0"
                          @click="ogeDuzenle(oi)"
                        >
                          <v-icon color="grey lighten-1">
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-action class="ml-2">
                        <v-btn small icon @click="ogeSil(oi)">
                          <v-icon small color="grey lighten-1">
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider
                      v-if="editedKisisellestirmeItem.ogeler.length > oi + 1"
                    />
                  </div>
                </draggable>
              </div>
              <v-row no-gutters align="center" class="mb-6 mb-md-9">
                <v-col cols="8" class="pr-3">
                  <v-select
                    v-model="editedKisisellestirmeItem.tarz"
                    outlined
                    rounded
                    hide-details
                    :label="$t('tarz')"
                    :items="tarzlar"
                  />
                </v-col>
                <v-col cols="4">
                  <v-switch
                    v-if="editedKisisellestirmeItem.tarz == 2"
                    v-model="editedKisisellestirmeItem.multiselect"
                    class="pa-0 ma-0"
                    color="primary"
                    hide-details=""
                    :label="
                      `${
                        editedKisisellestirmeItem.multiselect
                          ? $t('coklu')
                          : $t('tekil')
                      }`
                    "
                  />
                  <v-divider
                    v-if="
                      editedKisisellestirmeItem.tarz == 2 &&
                        editedKisisellestirmeItem.multiselect
                    "
                    class="my-2"
                  />
                  <v-switch
                    v-if="
                      editedKisisellestirmeItem.tarz == 1 ||
                        (editedKisisellestirmeItem.tarz == 2 &&
                          editedKisisellestirmeItem.multiselect)
                    "
                    v-model="editedKisisellestirmeItem.kural"
                    class="pa-0 ma-0"
                    color="primary"
                    hide-details=""
                    :label="
                      `${
                        editedKisisellestirmeItem.kural
                          ? $t('kuralli')
                          : $t('kuralsiz')
                      }`
                    "
                  />
                </v-col>
              </v-row>
              <v-row
                v-if="
                  (editedKisisellestirmeItem.tarz == 1 ||
                    (editedKisisellestirmeItem.tarz == 2 &&
                      editedKisisellestirmeItem.multiselect)) &&
                    editedKisisellestirmeItem.kural
                "
                no-gutters
                class="mb-6 mb-md-9"
              >
                <v-col cols="4" class="pr-3">
                  <v-text-field
                    v-model="editedKisisellestirmeItem.enAz"
                    outlined
                    rounded
                    hide-details
                    :label="$t('enAz')"
                  />
                </v-col>
                <v-col cols="4" class="pr-3">
                  <v-text-field
                    v-model="editedKisisellestirmeItem.enCok"
                    outlined
                    rounded
                    hide-details
                    :label="$t('enCok')"
                  />
                </v-col>
                <v-col cols="4" />
              </v-row>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions class=" px-3 primary rounded-0">
            <div class="px-3" style="width:100%">
              <v-btn
                block
                :large="$vuetify.breakpoint.smAndDown ? false : true"
                depressed=""
                class="rounded-pill text-capitalize text-body-1"
                color="primary"
                @click="kisisellestirmeEkle()"
              >
                {{ $t("kaydet") }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogKisisellestirmeOge"
        persistent
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        scrollable
        max-width="500px"
      >
        <v-card>
          <v-card-title class="py-3 py-md-6">
            <div class="text-center text-h4  text-md-h3 font-weight-medium">
              {{ $t("ozelOge") }}
            </div>
            <v-spacer />
            <v-btn
              x-small
              fab
              elevation="0"
              color="grey lighten-2"
              @click="dialogKisisellestirmeOge = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="px-2 py-0" style="max-height: 100%;">
            <div class="scroll pa-3">
              <v-text-field
                v-model="editedKisisellestirmeOgeItem.ad"
                :error="error && editedKisisellestirmeOgeItem.ad == ''"
                outlined
                rounded
                hide-details
                :label="$t('urunAd')"
                class="mb-6 mb-md-9"
              />
              <v-currency-field
                v-model="editedKisisellestirmeOgeItem.ilaveFiyat"
                outlined
                rounded
                hide-details
                :label="$t('ilaveFiyat')"
                class="mb-6 mb-md-9"
                suffix="€"
              />
              <v-select
                v-model="editedKisisellestirmeOgeItem.bilgilendirmeler"
                outlined
                rounded
                hide-details
                :label="$t('bilgilendirme')"
                :items="bilgilendirmeler"
                item-text="ad"
                item-value=".key"
                clearable
                multiple
                class="mb-6 mb-md-9"
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0" class="text-caption">
                    {{
                      $t("adetSecim", {
                        miktar:
                          editedKisisellestirmeOgeItem.bilgilendirmeler.length
                      })
                    }}
                  </span>
                </template>
              </v-select>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions class=" px-3 primary rounded-0">
            <div class="px-3" style="width:100%">
              <v-btn
                block
                :large="$vuetify.breakpoint.smAndDown ? false : true"
                depressed=""
                class="rounded-pill text-capitalize text-body-1"
                color="primary"
                @click="ogeEkle()"
              >
                {{ $t("kaydet") }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="error"
        color="error"
        :vertical="$vuetify.breakpoint.smAndDown ? true : false"
        top
      >
        <div class="d-flex align-center">
          <v-icon :size="$vuetify.breakpoint.smAndDown ? 24 : 36">
            mdi-alert
          </v-icon>
          <div class="text-h5 pl-3">
            {{ errorText }}
          </div>
        </div>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="error = false">
            {{ $t("kapat") }}
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>
<script>
import Compressor from "compressorjs";
import { get, sync } from "vuex-pathify";
import store from "./../store/index";
import { veri, depo } from "./../db";
import draggable from "vuedraggable";
import { O2A, FirstO2A } from "object-to-array-convert";
var _ = require("lodash");
export default {
  name: "UrunlerView",
  components: {
    draggable
  },
  data: () => ({
    search: "",
    tarzlar: [
      {
        text: "Checkbox",
        value: 1
      },
      {
        text: "Select",
        value: 2
      },
      {
        text: "Radio",
        value: 3
      }
    ],
    disabled: false,
    valid: false,
    validKisisellestirme: false,
    validKisisellestirmeOge: false,
    error: false,
    errorText: "",
    bekle: false,
    dialog: false,
    dialogFile: false,
    dialogDelete: false,
    dialogKisisellestirme: false,
    dialogKisisellestirmeOge: false,
    editedIndex: -1,
    editedItem: {
      ad: "",
      kategori: "",
      aciklama: "",
      bilgilendirmeler: "",
      kisisellestirmeler: [],
      fiyat: 0,
      indirim: 0,
      file: null,
      sira: 0,
      durum: true,
      stok: true,
      indirimDurum: false,
      indirimOran: 0,
      indirimsizFiyat: 0
    },
    defaultItem: {
      ad: "",
      kategori: "",
      aciklama: "",
      bilgilendirmeler: "",
      kisisellestirmeler: [],
      fiyat: 0,
      indirim: 0,
      file: null,
      sira: 0,
      durum: true,
      stok: true,
      indirimDurum: false,
      indirimOran: 0,
      indirimsizFiyat: 0
    },
    editedKisisellestirmeIndex: -1,
    editedKisisellestirmeItem: {
      ad: "",
      aciklama: "",
      tarz: 1,
      kural: false,
      enCok: 1,
      enAz: 0,
      multiselect: false,
      ogeler: []
    },
    defaultKisisellestirmeItem: {
      ad: "",
      aciklama: "",
      tarz: 1,
      kural: false,
      enCok: 1,
      enAz: 0,
      multiselect: false,
      ogeler: []
    },
    editedOgeIndex: -1,
    editedKisisellestirmeOgeItem: {
      ad: "",
      ilaveFiyat: 0,
      bilgilendirmeler: []
    },
    defaultKisisellestirmeOgeItem: {
      ad: "",
      ilaveFiyat: 0,
      bilgilendirmeler: []
    },
    urunler: [],
    kategoriler: [],
    bilgilendirmeler: [],
    kisisellestirmeler: []
  }),
  mounted() {
    veri.ref("/kisisellestirmeler/").on("value", data => {
      if (data.val()) {
        const value = O2A(data);
        this.kisisellestirmeler = value;
        return value;
      }
    });
  },
  firebase: {
    urunler: veri.ref("urunler"),
    kategoriler: veri.ref("kategoriler"),
    bilgilendirmeler: veri.ref("bilgilendirmeler")
    // kisisellestirmeler: veri.ref("kisisellestirmeler")
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t("yeniEkle") : this.$t("duzenle");
    },
    formAction() {
      return this.editedIndex === -1 ? this.$t("olustur") : this.$t("guncelle");
    },
    headers() {
      return [
        {
          text: this.$t("ad"),
          value: "ad"
        },
        {
          text: this.$t("kategori"),
          value: "kategori"
        },
        {
          text: this.$t("fiyat"),
          align: "right",
          sortable: false,
          value: "fiyat"
        },
        {
          text: this.$t("durum"),
          align: "right",
          sortable: false,
          value: "durum"
        },
        {
          text: "",
          value: "actions",
          align: "right",
          filterable: false
        }
      ];
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogKisisellestirme(val) {
      val || this.closeKisisellestirme();
    },
    dialogKisisellestirmeOge(val) {
      val || this.closeKisisellestirmeOge();
    }
  },
  methods: {
    errorSnackbar() {
      this.errorText = "";
      var elmnt = document.getElementById("scrollItem");
      if (this.editedItem.ad == "") {
        required: value => !!value || "Required.";
        this.error = true;
        this.errorText = this.$t("e1");
      } else if (this.editedItem.kategori == "") {
        this.error = true;
        this.errorText = this.$t("e2");
      } else if (this.editedItem.fiyat <= 0) {
        this.error = true;
        this.errorText = this.$t("e3");
        elmnt.scrollTop = 250;
      } else {
        this.valid = true;
      }
    },
    errorSnackbarKisisellestirme() {
      this.errorText = "";
      if (this.editedKisisellestirmeItem.ad == "") {
        this.error = true;
        this.errorText = this.$t("e4");
      } else if (this.editedKisisellestirmeItem.ogeler.length == 0) {
        this.error = true;
        this.errorText = this.$t("e5");
      } else {
        this.validKisisellestirme = true;
      }
    },
    errorSnackbarKisisellestirmeOge() {
      this.errorText = "";
      if (this.editedKisisellestirmeOgeItem.ad == "") {
        this.error = true;
        this.errorText = this.$t("e4");
      } else {
        this.validKisisellestirmeOge = true;
      }
    },
    icerikGetir(yer, id, val) {
      var deger = _.find(this[yer], {
        ".key": id
      });
      if (_.isObject(deger)) {
        deger = deger[val];
      } else {
        deger = "";
      }
      return deger;
    },
    editItem(item) {
      if (typeof item.kisisellestirmeler === "undefined") {
        item.kisisellestirmeler = [];
      }
      if (typeof item.file === "undefined") {
        item.file = null;
      }
      this.editedIndex = this.urunler.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    editItemFile(item) {
      this.editedIndex = this.urunler.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogFile = true;
    },
    deleteItemFile() {
      confirm(this.$t("silOnay")) && this.deleteItemFileConfirm();
    },
    deleteItemFileConfirm() {
      var fileUrl = this.urunler[this.editedIndex].file;
      var fileRef = depo.refFromURL(fileUrl);
      fileRef.delete().then(() => {
        veri
          .ref("urunler/" + this.urunler[this.editedIndex][".key"] + "/file")
          .set(null)
          .then(() => {
            this.dialogFile = false;
            this.close();
          });
      });
    },
    deleteItem(item) {
      confirm(this.$t("silOnay")) && this.deleteItemConfirm(item);
    },
    deleteItemConfirm(item) {
      if (item.file) {
        var fileRef = depo.refFromURL(item.file);
        fileRef.delete().then(() => {
          veri.ref("urunler/" + item[".key"]).remove();
        });
      } else {
        veri.ref("urunler/" + item[".key"]).remove();
      }
    },
    kisisellestirmeEkle() {
      this.errorSnackbarKisisellestirme();
      if (this.validKisisellestirme) {
        if (this.editedKisisellestirmeIndex > -1) {
          this.editedItem.kisisellestirmeler[
            this.editedKisisellestirmeIndex
          ] = this.editedKisisellestirmeItem;
        } else {
          this.editedItem.kisisellestirmeler.push(
            this.editedKisisellestirmeItem
          );
        }
        this.dialogKisisellestirme = false;
        this.closeKisisellestirme();
      }
    },
    kisisellestirmeDuzenle(val) {
      this.editedKisisellestirmeIndex = val;
      this.editedKisisellestirmeItem = Object.assign(
        {},
        this.editedItem.kisisellestirmeler[val]
      );
      this.dialogKisisellestirme = true;
    },
    kisisellestirmeSil(val) {
      confirm(this.$t("silOnay")) &&
        this.editedItem.kisisellestirmeler.splice(val, 1);
    },
    ogeEkle() {
      this.errorSnackbarKisisellestirmeOge();
      if (this.validKisisellestirmeOge) {
        if (this.editedOgeIndex > -1) {
          this.editedKisisellestirmeItem.ogeler[
            this.editedOgeIndex
          ] = this.editedKisisellestirmeOgeItem;
        } else {
          this.editedKisisellestirmeItem.ogeler.push(
            this.editedKisisellestirmeOgeItem
          );
        }
        this.dialogKisisellestirmeOge = false;
        this.closeKisisellestirmeOge();
      }
    },
    ogeDuzenle(val) {
      this.editedOgeIndex = val;
      this.editedKisisellestirmeOgeItem = Object.assign(
        {},
        this.editedKisisellestirmeItem.ogeler[val]
      );
      this.dialogKisisellestirmeOge = true;
    },
    ogeSil(val) {
      confirm(this.$t("silOnay")) &&
        this.editedKisisellestirmeItem.ogeler.splice(val, 1);
    },
    close() {
      this.bekle = false;
      this.dialog = false;
      this.valid = false;
      this.error = false;
      this.editedItem.kisisellestirmeler = [];
      this.editedItem.bilgilendirmeler = [];
      var elmnt = document.getElementById("scrollItem");
      elmnt.scrollTop = 0;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedKisisellestirmeItem = Object.assign(
          {},
          this.defaultKisisellestirmeItem
        );
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeKisisellestirme() {
      this.validKisisellestirme = false;
      this.error = false;
      this.$nextTick(() => {
        this.editedKisisellestirmeItem = Object.assign(
          {},
          this.defaultKisisellestirmeItem
        );
        this.editedKisisellestirmeIndex = -1;
      });
    },
    closeKisisellestirmeOge() {
      this.validKisisellestirmeOge = false;
      this.error = false;
      this.$nextTick(() => {
        this.editedKisisellestirmeOgeItem = Object.assign(
          {},
          this.defaultKisisellestirmeOgeItem
        );
        this.editedOgeIndex = -1;
      });
    },
    save() {
      this.errorSnackbar();
      if (this.valid) {
        this.bekle = true;
        if (this.editedIndex > -1) {
          if (_.isObject(this.editedItem.file)) {
            const file = this.editedItem.file;

            if (!file) {
              return;
            }
            var self = this;
            new Compressor(file, {
              quality: 0.6,
              minWidth: 800,
              height: 600,

              // The compression process is asynchronous,
              // which means you have to access the `result` in the `success` hook function.
              success(result) {
                var uploadTask = depo
                  .ref()
                  .child("images/" + Date.now() + "_" + result.name)
                  .put(result);
                uploadTask.on(
                  "state_changed",
                  sp => {},
                  null,
                  () => {
                    uploadTask.snapshot.ref
                      .getDownloadURL()
                      .then(downloadURL => {
                        self.editedItem.file = downloadURL;
                        veri
                          .ref(
                            "urunler/" + self.urunler[self.editedIndex][".key"]
                          )
                          .set(self.editedItem)
                          .then(self.close());
                      });
                  }
                );
              },
              error(err) {
                this.close();
              }
            });
          } else {
            veri
              .ref("urunler/" + this.urunler[this.editedIndex][".key"])
              .set(this.editedItem)
              .then(this.close());
          }
        } else {
          if (this.editedItem.file) {
            const file = this.editedItem.file;

            if (!file) {
              return;
            }
            var self = this;
            new Compressor(file, {
              quality: 0.6,
              minWidth: 800,
              height: 600,

              // The compression process is asynchronous,
              // which means you have to access the `result` in the `success` hook function.
              success(result) {
                var uploadTask = depo
                  .ref()
                  .child("images/" + Date.now() + "_" + result.name)
                  .put(result);
                uploadTask.on(
                  "state_changed",
                  sp => {},
                  null,
                  () => {
                    uploadTask.snapshot.ref
                      .getDownloadURL()
                      .then(downloadURL => {
                        self.editedItem.file = downloadURL;
                        veri
                          .ref("urunler")
                          .push(self.editedItem)
                          .then(self.close());
                      });
                  }
                );
              },
              error(err) {
                console.log(err.message);
              }
            });
          } else {
            veri
              .ref("urunler")
              .push(this.editedItem)
              .then(this.close());
          }
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.handle {
  cursor: move;
}

.v-progress-linear .v-progress-linear__indeterminate .long,
.v-progress-linear .v-progress-linear__indeterminate .short {
  background-color: #bcc0c4 !important;
}

.v-card__text .scroll {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden !important;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 20px;
  }
}

.v-card__text .scroll:hover {
  &::-webkit-scrollbar-thumb {
    background: #bcc0c4;
  }
}
tbody tr.v-data-table__mobile-table-row:nth-of-type(even) td {
  background-color: #eeeeee;
}
</style>
